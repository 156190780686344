<template lang="pug">
div
    q-carousel.q-mb-md(
        v-model="current"
        ref="carousel"
        transition-prev="jump-right"
        transition-next="jump-left"
        :arrows="true"
        animated
        :autoplay="7000"
        infinite
        padding
        :navigation="false"
        @transition="showAdv = false")
        q-carousel-slide.cursor-pointer(
            v-for="(slide, index) in slides"
            :name="index")
            router-link.slide-link(
                :to="`${slideLink(slide)}`"
                :title="`${slide.title}`"
                @click="slide.token ? sendMetrick() : ''"
            )
                img.full-width(
                    :src="slide.image"
                    :title="`${slide.title}`"
                    :alt="`${slide.title}`"
                  )
                .badge.text-center.flex.column.justify-center(v-if="slide.count")
                    .font-24.text-secondary.text-weight-bold.line-height-24
                        | {{ slide.count }}
                    .text-secondary.text-weight-medium.line-height-12
                        | товаров
                template(v-if="slide.token")
                    .adv-badge.row.items-center.justify-center(@click.prevent.stop="showAdv = !showAdv")
                        q-icon(
                            name="etabl:info_warning"
                            size="16px"
                            color="primary"
                            @click.prevent.stop=""
                        )
                        .font-12.text-primary.line-height-16.q-ml-xs
                            | Реклама
                    .bg-secondary.adv-card.b-radius-16.row.items-center#adv-card(v-if="showAdv" @click.prevent.stop="")
                        .text-primary.font-11 
                            | {{ slide.organizationName }}, ИНН {{ slide.inn }}
                        q-icon.q-ml-md(
                            name="etabl:copy"
                            size="14px"
                            color="positive"
                            @click="copy(`https://etabl.ru/marketing/${slide.id}?token=${slide.token}`)"
                        )
    .row.justify-center.full-width
        template(v-for="index in slides.keys()")
            q-btn.q-pa-none.q-mx-sm(
                flat
                rounded
                dense
                size="10px"
                @click.prevent="current = index")
                q-icon(
                    name="circle"
                    :color="index === current ? 'green-3' : 'grey-7'")
</template>

<script>
import { ref, watch, onMounted, computed } from 'vue';
import { useStore } from 'vuex';
import { copyToClipboard } from 'quasar';

export default {
    name: 'CarouselDesktop',
    props: {
        slides: {
            type: Array,
            required: true,
        },
        deliveryBanner: {
            type: Boolean,
            default: () => true,
        },
    },
    setup(props) {
        const current = ref(0);

        const store = useStore();
        const count = ref(0);
        const freeDelivery = ref(true);

        const categories = ref(null);
        const showAdv = ref(false);

        // город пользователя
        const city = computed(() => store.getters['cityForm/CITY']);

        const copy = (value) => {
            copyToClipboard(value);
        };

        const slideLink = (slide) => {
            if(slide.url) {
                if(city.value)
                    return `/${city.value.slug}${slide.url}`;
                else 
                    return `${slide.url}`;
            }
            if(slide.token) {
                if(city.value)
                    return `/${city.value.slug}/marketing/${slide.id}?token=${slide.token}`;
                else 
                    return `/marketing/${slide.id}?token=${slide.token}`;
            } else {
                if(city.value) 
                    return `/${city.value.slug}/marketing/${slide.id}`;
                else
                    return `/marketing/${slide.id}`;
            }
        };

        const sendMetrick = () => {
            try {
                ym(72234883,'reachGoal','banner_click');
            } catch {}
        };

        onMounted(async () => {
            if (process.env.CLIENT) {
                window.addEventListener('click', () => {
                    if(showAdv.value) showAdv.value = false;
                }); 

                window.addEventListener('scroll', () => {
                    if(showAdv.value) showAdv.value = false;
                });
            }
        });

        return { 
            current,
            count,
            categories,
            freeDelivery,
            copy,
            showAdv,
            city,
            slideLink,
            sendMetrick,
        };
    },
};
</script>

<style lang="scss" scoped>
.q-carousel {
    height: 285px;
    border-radius: $generic-border-radius;

    @media screen and (max-width: $breakpoint-lg-min) {
        height: 285px;
    }

    @media screen and (max-width: 1439px) {
        height: 270px;
    }

    @media screen and (max-width: 1279px) {
        height: 201px;
    }

    @media screen and (max-width: 960px) {
        height: 170px;
    }
}

.q-carousel :deep(.q-carousel__slides-container) {
    .q-carousel__slide {
        background-repeat: no-repeat;
        padding: 0;
        overflow: hidden;
    }
}

.q-carousel :deep(.q-carousel__arrow) {
    .q-btn {
        color: rgba(0, 0, 0, 0.4);
        background: rgba(255, 255, 255, 0.3);
    }
}

.badge {
    position: absolute;
    bottom: 70%;
    width: 100px;
    height: 50px;
    background: $negative;
    border-radius: 26px 0 0 26px;
    right: 0; 
}

.adv-badge {
    position: absolute;
    right: 8px;
    top: 8px; 
    background: $secondary;
    opacity: 0.7;
    width: 88px;
    padding: 4px 0;
    border-radius: 16px;
}

.adv-card {
    padding: 16px;
    top: 42px; 
    right: 8px; 
    position: absolute;
    opacity: 1;
}
</style>
